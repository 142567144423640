<template>
  <div class="login_container">
    <div class="login_box">
        <div class="logo">
            <img src="../assets/login_logo@1x.png" alt=""/>
        </div>
        <!--登录表单区域-->
        <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef" label-width="0px" class="login_form">
            <!--用户名-->
            <el-form-item prop="username">
                <el-input v-model="loginForm.username" prefix-icon="el-icon-user" placeholder="账号"></el-input>
            </el-form-item>
            <!--密码-->
            <el-form-item prop="password">
                <el-input type="password" v-model="loginForm.password" prefix-icon="el-icon-lock"  placeholder="密码"></el-input>
            </el-form-item>
        </el-form>
        <el-button type="primary" @click="login" :loading="loading" class="login-btn">登 录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,//按钮状态
      // 这是登录表单的数据绑定对象
      loginForm: {
          username: '',
        password: ''
      },
      // 表单校验
      loginFormRules: {
          username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ]
      }
    }
  },
  mounted(){
      //清空localStorage储存里的内容
    localStorage.clear();
  },
  methods: {
    //进入重置密码界面
    toResetPwd() {
      this.$router.push('/resetPwd')
    },
    // 重置表单，resetFields()后面不能加冒号，会报错
    resetLoginForm: function () {
      this.$refs.loginFormRef.resetFields()
    },
      //等了方法
    login: function () {
      this.$refs.loginFormRef.validate(async valid => {
        if (valid) {
          this.loading = true;
          let { data: res } = await this.$http({
            url: '/admin/login',
            data: this.loginForm,
            method:'post'
          });
          this.loading = false;
          if (res.code !== 200) {
            this.$message.error(res.message)
          } else {
            // 登录成功把token保存到localStorage
              window.localStorage.setItem('activePath', '/statistics')
            window.localStorage.setItem('token', res.data)
            await this.$router.push('/home')
            // 编程式导航跳转到后台主页，路由地址是/home
           // await this.getUserInfo()
          }
        } else {
          //验证不通过
        }
      })
    },
    //获取登录用户信息，并跳转
    async getUserInfo() {
      const { data: res } = await this.$http.get('/user/info')
      this.loading = false;
      if (res.code !== 200) {
        this.$message.error(res.msg)
      } else {
        window.localStorage.setItem('userInfo', JSON.stringify(res.data))
        await this.$router.push('/home')
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .login_container{width: 100%;height:100%;background-image: url('../assets/bg@3x.png');background-size: cover;}
  .login_box{width:368px;height:350px;position: absolute;left:50%;top:50%;transform: translate(-50%, -50%);}
    .logo {width: 170px;height: 44px;margin-left: 95px;}
  .logo img {width: 100%;height: 100%;object-fit: cover;}
  .login_form {margin-top: 94px;}
  .login-btn {margin-top: 48px;width: 368px;height: 40px;background: #1890FF;border-radius: 2px;font-size: 16px;}

</style>
<style>
.el-input__icon {font-size: 18px;}
.login_form .el-input__prefix {color: #1890FF;}
.login_form .el-input {font-size: 16px;}
</style>
